import Order from "gaia-sdk-js/lib/src/class/Order/Order";
import GtmManager from "./GtmManager";
import {DependencyInjectorInstance} from "horizon-js-front-sdk/lib/DependencyInjector";
import {ProfileRepository} from "../../repositories/ProfileRepository";
import Profile from "gaia-sdk-js/lib/src/class/Identity/Profile";
import EventProxyRepository from "../../repositories/EventProxyRepository";
import {Router} from "horizon-js-front-sdk/lib/Router";

export default class AnalyticsEvents {

	private static lsWaitingPurchaseEventsKey = 'waitingPurchaseEvents';
	private static eventProxyRepository: EventProxyRepository = DependencyInjectorInstance().getInstance(EventProxyRepository)

	static addOrderToWaitingPurchaseEvents(orderUid: string) {
		let waitingEvents: { [orderUid: string]: { eventSent: boolean } } = {};
		let rawWaitingEvents = localStorage.getItem(this.lsWaitingPurchaseEventsKey);
		if(rawWaitingEvents) {
			waitingEvents = JSON.parse(rawWaitingEvents);
		}

		waitingEvents[orderUid] = {
			eventSent: false
		};

		localStorage.setItem(this.lsWaitingPurchaseEventsKey, JSON.stringify(waitingEvents));
	}

	static getWaitingPurchaseEvents(): { [orderUid: string]: { eventSent: boolean } } {
		let waitingEvents: { [orderUid: string]: { eventSent: boolean } } = {};
		let rawWaitingEvents = localStorage.getItem(this.lsWaitingPurchaseEventsKey);
		if(rawWaitingEvents) {
			waitingEvents = JSON.parse(rawWaitingEvents);
		}
		return waitingEvents;
	}

	static setWaitingPurchaseEventSent(orderUid: string) {
		let waitingEvents: { [orderUid: string]: { eventSent: boolean } } = {};
		let rawWaitingEvents = localStorage.getItem(this.lsWaitingPurchaseEventsKey);
		if(rawWaitingEvents) {
			waitingEvents = JSON.parse(rawWaitingEvents);
		}

		if(waitingEvents[orderUid]) {
			waitingEvents[orderUid].eventSent = true;
		}

		localStorage.setItem(this.lsWaitingPurchaseEventsKey, JSON.stringify(waitingEvents));
	}

	static async sendAddedToCartEvent(products: { item_id: string, price: number, item_name: string, quantity: number }[], profileUid: string|null) {
		let profileRepository = DependencyInjectorInstance().getInstance(ProfileRepository);
		let profile: Profile|null = null;
		if(profileUid) {
			profile = await profileRepository.getOneFormatted(profileUid);
		}

		let value = products.reduce((acc, product) => acc + (product.price * product.quantity), 0);

		window[GtmManager.dataLayerName].push({
			event: 'add_to_cart',
			ecommerce: {
				items: products,
				currency: 'EUR',
				value: value
			},
			profile: {
				email: profile ? profile.getUser()?.email : null,
				firstname: profile ? profile.getUser()?.first_name : null,
				lastname: profile ? profile.getUser()?.last_name : null,
			}
		});
	}

	static async sendRemovedFromCartEvent(products: { item_id: string, price: number, item_name: string, quantity: number }[], profileUid: string|null) {
		let profileRepository = DependencyInjectorInstance().getInstance(ProfileRepository);
		let profile: Profile|null = null;
		if(profileUid) {
			profile = await profileRepository.getOneFormatted(profileUid);
		}

		let value = products.reduce((acc, product) => acc + (product.price * product.quantity), 0);

		window[GtmManager.dataLayerName].push({
			event: 'remove_from_cart',
			ecommerce: {
				items: products,
				currency: 'EUR',
				value: value
			},
			profile: {
				email: profile ? profile.getUser()?.email : null,
				firstname: profile ? profile.getUser()?.first_name : null,
				lastname: profile ? profile.getUser()?.last_name : null,
			}
		});
	}

	static async sendUpdatedCartEventWithProducts(products: { item_id: string, price: number, item_name: string, quantity: number }[], profileUid: string|null) {
		let profileRepository = DependencyInjectorInstance().getInstance(ProfileRepository);
		let profile: Profile|null = null;
		if(profileUid) {
			profile = await profileRepository.getOneFormatted(profileUid);
		}

		let value = products.reduce((acc, product) => acc + (product.price * product.quantity), 0);

		window[GtmManager.dataLayerName].push({
			event: 'updateCart',
			ecommerce: {
				items: products,
				currency: 'EUR',
				value: value
			},
			profile: {
				email: profile ? profile.getUser()?.email : null,
				firstname: profile ? profile.getUser()?.first_name : null,
				lastname: profile ? profile.getUser()?.last_name : null,
			}
		});

		if(profile) {
			let user = profile.getUser();

			if(user) {
				let matches = Router.getMainRouter()?.lastRouteRegexMatches;
				if (matches) {
					this.eventProxyRepository.sendEvent(matches[1], 'cart_updated', user.email ?? '', {
						items: products,
						currency: 'EUR',
						value: value
					});
				}
			}
		}
	}

	static async sendOrderEvent(order: Order, eventName: string) {
		let profileRepository = DependencyInjectorInstance().getInstance(ProfileRepository);
		let profile: Profile|null = null;
		if(order.buyerProfileUid) {
			profile = await profileRepository.getOneFormatted(order.buyerProfileUid);
		}

		let products: { item_id: string, price: number, item_name: string, quantity: number }[] = [];
		for(let purchase of order.purchases) {
			if(purchase.childPurchases.length > 0) {
				for(let childPurchase of purchase.childPurchases) {
					products.push({
						item_id: childPurchase.productUid??'',
						price: childPurchase.priceWithTax,
						quantity: childPurchase.quantity,
						item_name: purchase.name + ' - ' + childPurchase.name,
					});
				}
			} else {
				products.push({
					item_id: purchase.productUid??'',
					price: purchase.priceWithTax,
					quantity: purchase.quantity,
					item_name: purchase.name,
				});
			}
		}

		window[GtmManager.dataLayerName].push({
			event: eventName,
			ecommerce: {
				value: order.totalPriceWithTax,
				currency: 'EUR',
				items: products,
			},
			profile: {
				email: profile ? profile.getUser()?.email : null,
				firstname: profile ? profile.getUser()?.first_name : null,
				lastname: profile ? profile.getUser()?.last_name : null,
			}
		});

		if(eventName === 'purchase') {
			this.setWaitingPurchaseEventSent(order.uid ?? '');

			if(profile) {
				let user = profile.getUser();

				if(user) {
					this.eventProxyRepository.sendEvent(order.establishmentUid??'', 'order_completed', user.email??'', {
						value: order.totalPriceWithTax,
						currency: 'EUR',
						items: products,
					});
				}
			}
		}
	}
}