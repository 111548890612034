import {Address} from "./BillingAddress";
import Model from "gaia-sdk-js/lib/src/class/Model";

export default class LegalInformation extends Model {
	set legalFormLongVersion(value: string) {
		let i: number = 0;
		this.legalForm = "";
		if (value.startsWith("EIRL")) this.legalForm = "EIRL";
		else if (value.startsWith("EI")) this.legalForm = "EI";
		else if (value.startsWith("EURL")) this.legalForm = "EURL";
		else if (value.startsWith("SARL")) this.legalForm = "SARL";
		else if (value.startsWith("SASU")) this.legalForm = "SASU";
		else if (value.startsWith("Société par actions simplifiée à associé unique")) this.legalForm = "SASU";
		else if (value.startsWith("SAS")) this.legalForm = "SAS";
		else if (value.startsWith("SNC")) this.legalForm = "SNC";
		else if (value.startsWith("SCOP")) this.legalForm = "SCOP";
		else if (value.startsWith("SA")) this.legalForm = "SA";
		else if (value.startsWith("Association déclarée")) this.legalForm = "Association déclarée";
		else if (value.startsWith("Association reconnue d'utilisaté publique")) this.legalForm = "RUP";
		else if (value.startsWith("Fondation")) this.legalForm = "Fondation";

		else this.legalForm = "SARL";
	}

	public uid!: Uuid;

	//(Numéro Interne de classement)
	private _NIC: string = "";
	get NIC(): string {
		return this._NIC;
	}

	set NIC(value: string) {
		if (value.length > 5) {
			value = value.slice(0, 5);
		}
		this._NIC = value;
	}


	private _siren: string = "";
	get siren(): string {
		return this._siren;
	}

	set siren(value: string) {
		if (value && value.length > 9) {
			value = value.slice(0, 9);
		}
		this._siren = value;
	}

	get siret(): string {
		return this._siren + this._NIC;
	}

	set siret(value: string) {
		let i: number = 0;
		if (value.length > 9) {
			this._NIC = value.substr(9, value.length - 1);
		}
	}

	public vatNumber!: string;
	public rcs!: string;
	public legalForm: string = "SARL";

	public phoneNumber!: string;
	public email!: string;
	public lastname!: string;
	public firstname!: string;
	public companyName!: string;
	public beforeText!: string;
	public afterText!: string;
	public showAddressOpposition: boolean = false;

	private _isBillingAdresseSameAsAddress: boolean = true;
	get isBillingAdresseSameAsAddress(): boolean {
		return this._isBillingAdresseSameAsAddress;
	}

	set isBillingAdresseSameAsAddress(value: boolean) {
		this._isBillingAdresseSameAsAddress = value;
	}

	public address: Address = new Address();
	public billingAddress: Address = new Address();

	/**
	 * Construct a product from a javascript object of a json string
	 * @param obj either a javascript object or a json string
	 */
	constructor(obj: any = {}) {
		super();
		if (typeof obj === 'string') {
			obj = JSON.parse(obj);
		}
		LegalInformation.buildFromJson(this, obj);
	}

	/**
	 * builds the class instance from a javascript object
	 * @param instance this
	 * @param json javascript object
	 */
	private static buildFromJson(instance: any, json: any) {
		if (json) {
			for (let prop in json) {
				if (json.hasOwnProperty(prop)) {
					if (prop === 'address' || prop === 'billingAddress') {
						instance[prop] = new Address(json[prop]);
					} else {
						instance[prop] = json[prop];
					}
				}
			}
		}
	}

	//TODO: Do this function
	exportToJson() {
		let JSON: any = {};
		JSON.uid = this.uid;
		JSON.siret = this.siret;
		JSON.siren = this._siren;
		JSON.vatNumber = this.vatNumber;
		JSON.rcs = this.rcs;
		JSON.legalForm = this.legalForm;
		JSON.phoneNumber = this.phoneNumber;
		JSON.email = this.email;
		JSON.lastname = this.lastname;
		JSON.firstname = this.firstname;
		JSON.companyName = this.companyName;
		JSON.beforeText = this.beforeText;
		JSON.afterText = this.afterText;
		JSON.address = this.address.exportToJson();
		if (this._isBillingAdresseSameAsAddress) {
			JSON.billingAddress = this.address.exportToJson();
		} else {
			JSON.billingAddress = this.billingAddress.exportToJson();
		}
		if (!this.address && !this.billingAddress)
			JSON.showAddressOpposition = true;

		return JSON;
	}
}