import Order from "gaia-sdk-js/lib/src/class/Order/Order";
import Model from "gaia-sdk-js/lib/src/class/Model";

export default class Cart extends Model {
	public establishmentUid!: Uuid;
	public profileUid!: Uuid|null;
	public order: Order = new Order();

	constructor(obj: any = {}) {
		super();
		if (typeof obj === 'string') {
			obj = JSON.parse(obj);
		}
		Cart.buildFromJson(this, obj);
	}

	private static buildFromJson(instance: any, json: any) {
		if (json) {
			for (let prop in json) {
				if (json.hasOwnProperty(prop)) {
					if (prop === 'order') {
						instance[prop] = new Order(json[prop]);
					} else {
						instance[prop] = json[prop];
					}
				}
			}
		}
	}

	exportToJson(): any {
		let JSONCart: any = {};

		JSONCart.establishmentUid = this.establishmentUid;
		JSONCart.profileUid = this.profileUid;
		JSONCart.order = this.order.exportToJson();

		return JSONCart;
	}

	public static findProfileEstablishmentCart(establishmentUid: Uuid, profileUid: Uuid|null, carts: Cart[]): Cart|null {
		for(let cart of carts) {
			if(cart.establishmentUid === establishmentUid && cart.profileUid === profileUid) {
				return cart;
			}
		}
		return null;
	}
}