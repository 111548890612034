import Repository from "./Repository";
import UserNaturalBilling from "../model/UserNaturalBilling";
import Introspection from "../model/Introspection";
import jwt_decode from "jwt-decode";
import User from "gaia-sdk-js/lib/src/class/Identity/User";
import {Company} from "gaia-sdk-js/lib/src/class/Identity/Company";
import CustomerLabel from "../class/User/CustomerLabel";

export default class UserRepository extends Repository {
	/**@TODO : apiUrl here is "baseUrl" -> ndd.com and no ndd.com/api/vX **/


	constructor() {
		super();
	}

	public createUserNatural(user: User, customData = {}): Promise<User> {
		let raw = user.exportToJson();
		Object.assign(raw, customData);
		return this.request(this.config.identityGateway_apiUrl + 'v1/Users/', raw, "POST", false).then((JSONUser: any) => {
			let user = new User(JSONUser);
			return user;
		}).catch(function (data: any) {
			return data;
		});
	}

	public createUserLegal(user: Company): Promise<Company> {
		return this.request(this.config.identityGateway_apiUrl + 'v1/Companies/', user.exportToJson(), "POST", false).then(function (JSONUser: any) {
			return new Company(JSONUser);
		}).catch(function (data: any) {
			return data;
		});
	}

	public createAddress(userBilling: UserNaturalBilling): Promise<any> {
		return this.request(this.apiUrl + 'v1/Users/' + userBilling.user.uid + '/Address/', userBilling.exportToJson(), "POST", false).then(function (JSON: any) {
			return JSON;
		}).catch(function (data: any) {
			return data;
		});
	}

	public introspect(): Introspection {
		let accessToken: string = localStorage.getItem('oauth-token') || '';
		let introspection: Introspection = new Introspection();

		introspection.accessToken = accessToken;
		try {
			let jwtContent: any = jwt_decode(accessToken);
			introspection.uid = jwtContent.userId;
			introspection.exp = jwtContent.exp;
			introspection.iat = jwtContent.iat;
		} catch (err) {
			introspection.uid = null;
		}

		return introspection;
	}

	public getMyEstablishmentsLabels(profileUid: string, establishmentUid: string): Promise<CustomerLabel[]> {
		return this.request(this.apiUrl
			+ 'v1/Profiles/' + profileUid + '/Establishments/' + establishmentUid + "/Labels/", null, 'GET').then((JSONCustomerLabels: any) => {

			let customerLabels: CustomerLabel[] = [];
			for (let JSONCustomerLabel of JSONCustomerLabels) {
				customerLabels.push(new CustomerLabel(JSONCustomerLabel));
			}
			return customerLabels;

		});
	}
}
