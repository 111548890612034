import Repository from "./Repository";
import Cart from "../model/Cart";
import {Autowire} from "horizon-js-front-sdk/lib/DependencyInjector";
import UserRepository from "./UserRepository";
import ProductCart from "gaia-sdk-js/lib/src/class/Order/ProductCart";
import ProductContextVariable from "gaia-sdk-js/lib/src/class/ContextVariable/ProductContextVariable";
import Purchase from "gaia-sdk-js/lib/src/class/Order/Purchase";
import {PurchaseItemStockDetails} from "gaia-sdk-js/lib/src/class/Order/PurchaseItemStockDetails";
import {PurchaseItemStockTemporal} from "gaia-sdk-js/lib/src/class/Order/PurchaseItemStockTemporal";
import {PurchaseItem} from "gaia-sdk-js/lib/src/class/Order/PurchaseItem";
import AnalyticsEvents from "../class/Util/AnalyticsEvents";

export default class CartRepository extends Repository {
	@Autowire(UserRepository) userRepository!: UserRepository;

	constructor() {
		super();
	}

	private handlers: Function[] = [];

	public subscribe(fn: Function) {
		this.handlers.push(fn);
	}

	public unsubscribe(fn: Function) {
		this.handlers = this.handlers.filter(
			function(item) {
				if (item !== fn) {
					return item;
				}
			}
		);
	}

	public fire(o, thisObj) {
		let scope = thisObj || window;
		this.handlers.forEach(function(item) {
			item.call(scope, o);
		});
	}

	exportCartsToJson(carts: Cart[]): any {
		let jsonCarts: any = [];
		for(let cart of carts) {
			jsonCarts.push(cart.exportToJson());
		}
		return JSON.stringify(jsonCarts);
	}

	public save(cart: Cart) {
		let savedCarts: Cart[] = this.getAll();

		let existingCart: Cart|null = null;
		let existingCartIndex!: number;
		for(let i = 0; i < savedCarts.length; i++) {
			if(savedCarts[i].establishmentUid === cart.establishmentUid && savedCarts[i].profileUid === cart.profileUid) {
				existingCart = savedCarts[i];
				existingCartIndex = i;
			}
		}

		if(existingCart) {
			savedCarts.splice(existingCartIndex, 1);
		}
		savedCarts.push(cart);

		localStorage.setItem('carts', this.exportCartsToJson(savedCarts));

		// Notify observers
		this.fire(cart, this);
	}

	private static findCartByEstablishmentProfile(establishmentUid: Uuid, profileUid: Uuid|null, carts: Cart[]): Cart|null {
		for (let cart of carts) {
			if (cart.establishmentUid === establishmentUid && cart.profileUid === profileUid) {
				return cart;
			}
		}
		return null;
	}

	// Merge purchases of c1 into c2
	private static mergeCarts(c1: Cart, c2: Cart|null, profileUid: Uuid): Cart {
		if(c2 === null) {
			c2 = new Cart();
			c2.establishmentUid = c1.establishmentUid;
			c2.profileUid = profileUid;
			c2.order = c1.order;
		} else if(c2.order === null) {
			c2.order = c1.order;
		} else {
			for(let purchase of c1.order.purchases) {
				c2.order.purchases.push(purchase);
			}
		}
		return c2;
	}

	// If user created a cart while logged out
	// we merge it with his account cart
	// when he log in
	private mergeIfConnected(establishmentUid: Uuid, carts: Cart[]): Cart[] {
		let introspect = this.userRepository.introspect();
		if(introspect.uid) {
			let loggedOutCart = CartRepository.findCartByEstablishmentProfile(establishmentUid,  null, carts);
			let profileCart = CartRepository.findCartByEstablishmentProfile(establishmentUid, introspect.uid, carts);

			if(loggedOutCart !== null && loggedOutCart.order !== null) {
				let mergedCart = CartRepository.mergeCarts(loggedOutCart, profileCart, introspect.uid);

				carts = this.deleteByEstablishmentProfile(establishmentUid, loggedOutCart.profileUid);
				if(profileCart) {
					carts = this.deleteByEstablishmentProfile(establishmentUid, profileCart.profileUid);
				}

				carts.push(mergedCart);
			}
			localStorage.setItem('carts', this.exportCartsToJson(carts));
		}
		return carts;
	}

	public getAll(): Cart[] {
		let savedCarts: any = localStorage.getItem('carts');
		if(savedCarts === null) {
			return [];
		}

		savedCarts = JSON.parse(savedCarts);
		let carts: Cart[] = [];
		for(let cart of savedCarts) {
			carts.push(new Cart(cart));
		}
		return carts;
	}

	public getByProfileEstablishment(establishmentUid: Uuid, profileUid: Uuid|null): Cart {
		let carts = this.getAll();
		carts = this.mergeIfConnected(establishmentUid, carts);

		let found = Cart.findProfileEstablishmentCart(establishmentUid, profileUid, carts);
		if(found) {
			return found;
		}
		return new Cart();
	}

	public addProduct(establishmentUid: Uuid, profileUid: Uuid|null, cartProduct: ProductCart, context: ProductContextVariable[]|ProductContextVariable|null) {
		let carts: Cart[] = this.getAll();
		let cart = Cart.findProfileEstablishmentCart(establishmentUid, profileUid, carts);

		if(!cart) {
			cart = new Cart();
			cart.establishmentUid = establishmentUid;
			cart.profileUid = profileUid;
		}

		let params: any = {
			product: cartProduct.product,
			quantity: cartProduct.quantity.simpleCount,
			statusCanceled: '',
			statusCart: '',
			currentContext: context
		};

		console.log(params);
		let purchase: Purchase|null = cart.order.add_product(params);

		if(purchase !== null) {
			for (let childPurchase of purchase.childPurchases) {
				let found = false;

				for (let cartItem of cartProduct.productCartItems) {
					if(childPurchase.itemUid === cartItem.uid) {
						found = true;

						// Case stock is simple we simply change quantity,
						// but if stock is temporal we create one item per
						// day and we set different time and quantity for each
						if(Object.entries(cartItem.quantity.temporalCount).length === 0) {
							childPurchase.items[0].quantity = cartItem.quantity.simpleCount;
						} else {
							let index: number = 0;
							for (const [day, stock] of Object.entries(cartItem.quantity.temporalCount)) {
								childPurchase.items[index] = new PurchaseItem({
									quantity: stock.value,
									stockDetails: {
										temporal: {
											time: day,
											name: stock.name
										}
									}
								});
								index++;
							}
						}
						break;
					}
				}

				if(!found) {
					childPurchase.items[0].quantity = 0;
				}
			}

			// Verify that there is no purchase with quantity of 0
			for(let i = 0; i < purchase.childPurchases.length; i++) {
				for(let j = 0; j < purchase.childPurchases[i].items.length; j++) {
					if(purchase.childPurchases[i].items[j].quantity === 0) {
						purchase.childPurchases[i].items.splice(j, 1);
						j--;
					}
				}
				if(purchase.childPurchases[i].items.length === 0) {
					purchase.childPurchases.splice(i, 1);
					i--;
				}
			}

			this.sendAddedToCartEvent(cartProduct);
		}

		this.save(cart);

		// Notify observers
		this.fire(cart, this);
	}

	public deleteByEstablishmentProfile(establishmentUid: Uuid, profileUid: Uuid|null): Cart[] {
		let carts: Cart[] = this.getAll();

		let index: number = 0;
		for(let cart of carts) {
			if(cart.establishmentUid === establishmentUid && cart.profileUid === profileUid) {
				carts.splice(index, 1);
				break;
			}
			index++;
		}
		localStorage.setItem('carts', this.exportCartsToJson(carts));

		return carts;
	}

	public empty() {
		localStorage.setItem('carts', '[]');

		// Notify observers
		this.fire(new Cart(), this);
	}

	public emptyByProfileEstablishment(establishmentUid: string, profileUid: Uuid|null) {
		let cart: Cart = new Cart();
		cart.establishmentUid = establishmentUid;
		cart.profileUid = profileUid;
		this.save(cart);

		// Notify observers
		this.fire(cart, this);
	}

	sendAddedToCartEvent(cartProduct: ProductCart) {
		let products: any[] = [];
		if(cartProduct.productCartItems.length > 0) {
			// For events with push only child products
			for(let item of cartProduct.productCartItems) {
				products.push({
					name:  cartProduct.name + ' - ' + item.name,
					id: item.uid,
					price: item.priceWithTax,
					quantity: item.quantity.simpleCount,
				})
			}
		} else {
			products.push({
				name: cartProduct.name,
				id: cartProduct.uid,
				price: cartProduct.priceWithTax,
				quantity: cartProduct.quantity.simpleCount,
			})
		}

		let introspect = this.userRepository.introspect();
		AnalyticsEvents.sendAddedToCartEvent(products, introspect.uid);
		AnalyticsEvents.sendUpdatedCartEventWithProducts(products, introspect.uid);
	}
}