import {VueClass, VueVar} from "horizon-js-front-sdk/lib/VueAnnotate";
import {Autowire} from "horizon-js-front-sdk/lib/DependencyInjector";
import CartRepository from "../../repositories/CartRepository";
import Vue from "vue";
import Order from "gaia-sdk-js/lib/src/class/Order/Order";
import {Router} from "horizon-js-front-sdk/lib/Router";
import Cart from "../../model/Cart";
import Introspection from "../../model/Introspection";
import Profile from "gaia-sdk-js/lib/src/class/Identity/Profile";
import {ProfileRepository} from "../../repositories/ProfileRepository";
import UserRepository from "../../repositories/UserRepository";
import AuthenticationManager from "horizon-js-front-sdk/lib/AuthenticationManager";
import Config from "../../Config";

@VueClass()
export class FooterComponent extends Vue {
	@VueVar() year: number = new Date().getFullYear();

	@Autowire(Config) config!: Config;

	constructor(container: string, vueConstructor: any = null) {
		super(vueConstructor);
	}
}