import AuthenticationManager from "horizon-js-front-sdk/lib/AuthenticationManager";
import EstablishmentRepository from "../repositories/EstablishmentRepository";
import ProfileEstablishment from "gaia-sdk-js/lib/src/class/ProfileEstablishment";

export default class Session {

	static setCurrentEstablishment(establishmentUid: string) {
		if (establishmentUid === null)
			window.localStorage.removeItem('partner_establishment');
		else
			window.localStorage.setItem('partner_establishment', establishmentUid);
	};

	static setCurrentPartnerPermissions(permissions: Array<string>) {
		if (permissions === null)
			window.localStorage.removeItem('partner_permissions');
		else
			window.localStorage.setItem('partner_permissions', JSON.stringify(permissions));
	}

	static setUserEstablishments(partners: Array<ProfileEstablishment>) {
		if (partners === null)
			window.localStorage.removeItem('user_partner_identities');
		else
			window.localStorage.setItem('user_partner_identities', JSON.stringify(partners));
	}

	static getUserEstablishments(): Promise<Array<ProfileEstablishment>> {
		let rawUserEstablishments: any;
		let userPartners: Array<ProfileEstablishment> = [];

		let userPartnerIdentitiesFromLS = window.localStorage.getItem('user_partner_identities');
		if (userPartnerIdentitiesFromLS) {
			rawUserEstablishments = JSON.parse(userPartnerIdentitiesFromLS);

			if (rawUserEstablishments && rawUserEstablishments.length > 0) {
				for (let userEstablishment of rawUserEstablishments) {
					userPartners.push(new ProfileEstablishment(userEstablishment));
				}
				return Promise.resolve(userPartners);
			}
		}
		// Si récupérer les établissements n'est pas possible.
		let waitingPromise: Array<Promise<any>> = [];

		let establisbmentRepository = new EstablishmentRepository();
		waitingPromise.push(establisbmentRepository.getUserEstablishments(AuthenticationManager.getUserUid()).then(function (userEstablishments: Array<ProfileEstablishment>) {
			userPartners = userEstablishments;
		}));

		return Promise.all(waitingPromise).then(function () {
			Session.setUserEstablishments(userPartners);
			return userPartners;
		});
	}

	static getCurrentEstablishmentUid(): Uuid {
		let establishmentUid = <Uuid>window.localStorage.getItem('partner_establishment')
		if (establishmentUid !== null) {
			return establishmentUid;
		} else {
			return establishmentUid;
		}
	};

	static getCurrentEstablishment(): Promise<ProfileEstablishment | null> {
		return Session.getUserEstablishments().then((userEstablishements: Array<ProfileEstablishment>) => {
			if (userEstablishements.length > 0) {
				let currentEstablishmentUid = Session.getCurrentEstablishmentUid();
				if (currentEstablishmentUid !== null) {
					for (let userPartner of userEstablishements) {
						if (userPartner.establishmentUid === currentEstablishmentUid) {
							return userPartner;
						}
					}
					return userEstablishements[0];
				} else {
					Session.setCurrentEstablishment(userEstablishements[0].establishmentUid);
					return Promise.resolve(userEstablishements[0]);
				}
			} else {
				return null;
			}
		});
	}

	static getOauthToken(): string {
		let oauthToken = window.localStorage.getItem('oauth-token');
		if (oauthToken !== null) {
			return oauthToken;
		} else {
			return '';
		}
	}

}