import Repository from "./Repository";

export default class FileRepository extends Repository {
	constructor() {
		super();
	}

	public base64File(establishmentUid: string, upload:any): Promise<any>{
		return this.request(this.apiUrl + 'v1/Establishments/'+establishmentUid+'/Uploads/Base64', upload, 'POST');
	};

	public getImage(uid : string): string{
		return this.uploadUrl+uid+'/';
	};
}