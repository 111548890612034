import Model from "gaia-sdk-js/lib/src/class/Model";

export default class CustomerLabel extends Model {

	public establishmentUid!: string;
	public labelUid!: string;
	public name!: string;
	public profileUid!: string;

	constructor(obj: any = {}) {
		super();
		if (typeof obj === 'string') {
			obj = JSON.parse(obj);
		}
		CustomerLabel.buildFromJson(this, obj);
	}

	private static buildFromJson(instance: any, json: any) {
		if (json) {
			for (let prop in json) {
				if (json.hasOwnProperty(prop)) {
					instance[prop] = json[prop];
				}
			}
		}
	}

	exportToJson(): any {
		let JSONObject: any = {};

		JSONObject.establishmentUid = this.establishmentUid;
		JSONObject.labelUid = this.labelUid;
		JSONObject.name = this.name;
		JSONObject.profileUid = this.profileUid;

		return JSONObject;
	}
}