import Countries from "./Countries";
import Model from "gaia-sdk-js/lib/src/class/Model";

export default class BillingAddress extends Model {
	set address(value: Address) {
		this._address = value;
	}
	set natural(value: BillingAddress_Natural) {
		this._natural = value;
	}
	set legal(value: BillingAddress_Legal) {
		this._legal = value;
	}
	get address(): Address {
		if(this._address==null){
			this._address=new Address();
		}
		return this._address;
	}
	get natural(): BillingAddress_Natural {
		if(this._natural==null){
			this._natural=new BillingAddress_Natural();
		}
		return this._natural;
	}
	get legal(): BillingAddress_Legal {
		return this._legal;
	}

	public hideAddress:boolean=false;
	private _legal!: BillingAddress_Legal;
	private _natural!: BillingAddress_Natural;
	private _address!: Address;

	/** Constructor **/
	constructor(obj: any = {}) {
		super();
		if (typeof obj === 'string') {
			obj = JSON.parse(obj);
		}
		BillingAddress.buildFromJson(this, obj);
	}

	/** Functions **/
	private static buildFromJson(instance: any, json: any) {
		if (json) {
			for (let prop in json) {
				if (json.hasOwnProperty(prop)) {
					// if (prop === 'characteristics') {
					//     for (let subProp in json[prop]) {
					//         if (json[prop].hasOwnProperty(subProp)) {
					//             instance[prop].push(new Characteristic(json[prop][subProp]));
					//         }
					//     }
					if (prop === 'natural') {
						instance[prop] = new BillingAddress_Natural(json[prop]);
					} else if (prop === 'legal') {
						instance[prop] = new BillingAddress_Legal(json[prop]);
					} else {
						instance[prop] = json[prop];
						// }
					}
				}
			}
		}
	}

	public exportToJson(): any {
		let JSONBillingAddress: any = {};
		if(this._natural!=null){
			JSONBillingAddress.natural = this._natural.exportToJson();
		}
		if(this._legal!=null){
			JSONBillingAddress.legal = this._legal.exportToJson();
		}
		if(!this.hideAddress && this._address!=null){
			JSONBillingAddress.address = this._address.exportToJson();
		}
		return JSONBillingAddress;
	}

}

export class BillingAddress_Natural extends Model {
	set lastname(value: string) {
		this._lastname = value;
	}
	set firstname(value: string) {
		this._firstname = value;
	}
	get lastname(): string {
		return this._lastname;
	}

	get firstname(): string {
		return this._firstname;
	}

	private _firstname!: string;
	private _lastname!: string;

	/** Constructor **/
	constructor(obj: any = {}) {
		super();
		if (typeof obj === 'string') {
			obj = JSON.parse(obj);
		}
		BillingAddress_Natural.buildFromJson(this, obj);
	}

	/** Functions **/
	private static buildFromJson(instance: any, json: any) {
		if (json) {
			for (let prop in json) {
				if (json.hasOwnProperty(prop)) {
					instance[prop] = json[prop];
				}
			}
		}
	}

	public exportToJson(): any {
		let JSONBilling_address_natural: any = {};
		JSONBilling_address_natural.firstname = this._firstname;
		JSONBilling_address_natural.lastname = this._lastname;
		return JSONBilling_address_natural;
	}

}

export class BillingAddress_Legal extends Model {

	name!: string;
	_headquarterAddress!:Address;
	get headquarterAddress(): Address {
		if(this._headquarterAddress==null){
			this._headquarterAddress=new Address();
		}
		return this._headquarterAddress;
	}
	set headquarterAddress(value: Address) {
		this._headquarterAddress = value;
	}
	rcs!: string;
	siret!: string;
	siren!: string;
	legalForm!: string;
	shareCapital!: string;
	vatNumber!: string;

	/** Constructor **/
	constructor(obj: any = {}) {
		super();
		if (typeof obj === 'string') {
			obj = JSON.parse(obj);
		}
		BillingAddress_Legal.buildFromJson(this, obj);
	}

	/** Functions **/
	private static buildFromJson(instance: any, json: any) {
		if (json) {
			for (let prop in json) {
				if (json.hasOwnProperty(prop)) {
					instance[prop] = json[prop];
				}
			}
		}
	}

	public exportToJson(): any {
		let JSONBilling: any = {};
		JSONBilling.name = this.name;
		JSONBilling.headquarterAddress = this.headquarterAddress.exportToJson();
		JSONBilling.rcs = this.rcs;
		JSONBilling.siret = this.siret;
		JSONBilling.legalForm = this.legalForm;
		JSONBilling.shareCapital = this.shareCapital;
		JSONBilling.vatNumber = this.vatNumber;
		return JSONBilling;
	}

}

export class Address extends Model {
	public line1!: string;
	public line2!: string;
	public city!: string;
	public region!: string;
	private _country: string="FR";
	get country(): string {
		return this._country;
	}

	set country(value: string) {
		if(Countries.is_valid_country(value)){
			this._country = value;
		}
	}
	public zipCode!: string;

	/** Constructor **/
	constructor(obj: any = {}) {
		super();
		if (typeof obj === 'string') {
			obj = JSON.parse(obj);
		}
		Address.buildFromJson(this, obj);
	}

	/** Functions **/
	private static buildFromJson(instance: any, json: any) {
		if (json) {
			for (let prop in json) {
				if (json.hasOwnProperty(prop)) {
					instance[prop] = json[prop];
				}
			}
		}
	}

	public exportToJson(): any {
		let JSONAddress: any = {};
		JSONAddress.line1 = this.line1;
		JSONAddress.line2 = this.line2;
		JSONAddress.city = this.city;
		JSONAddress.region = this.region;
		JSONAddress.country = this._country;
		JSONAddress.zipCode = this.zipCode;
		return JSONAddress;
	}

	getFormattedAddress(){
		let address:string="";
		address+=this.line1+' '+this.zipCode+' '+this._country;
		return address;
	}
}