import Repository from "./Repository";
import Session from "../model/Session";
import StorageLib from "../libs/StorageLib";
import {Error_Api} from "../libs/Error_Api";
import LegalInformation from "../model/LegalInformation";
import Establishement from "gaia-sdk-js/lib/src/class/Establishment/Establishment";
import ProfileEstablishment from "gaia-sdk-js/lib/src/class/ProfileEstablishment";

type EstablishmentCache = {
	[establishmentUid: string]: {
		establishment: Establishement,
		expires: number
	}
}

export default class EstablishmentRepository extends Repository {
	private promiseEstablishment!: Promise<Establishement>;

	private lsEstablishmentsKey = 'cached-establishments';

	constructor() {
		super();
	}

	getCachedEstablishment(establishmentUid): Establishement|null {
		let rawCache = localStorage.getItem(this.lsEstablishmentsKey);
		if(rawCache) {
			let cache = JSON.parse(rawCache);
			if(cache[establishmentUid] && cache[establishmentUid]['expires'] > Date.now()) {
				return new Establishement(cache[establishmentUid]['establishment']);
			}
		}
		return null;
	}

	addEstablishmentToCache(establishment: Establishement) {
		if(establishment.uid === null)
			return;

		let rawCache = localStorage.getItem(this.lsEstablishmentsKey) ?? '{}';
		let cache = JSON.parse(rawCache);

		if(cache[establishment.uid] === undefined) {
			cache[establishment.uid] = {}
		}
		cache[establishment.uid]['establishment'] = establishment.exportToJson();
		cache[establishment.uid]['expires'] = Date.now() + 604800000; // 7 days cache

		localStorage.setItem(this.lsEstablishmentsKey, JSON.stringify(cache))
	}

	public getAll(): Promise<Establishement[]> {
		return this.request(this.apiUrl + 'v1/Establishments/').then((JSONEstablishment: any) => {
			let establishments = [];
			for(let establishment of establishments) {
				establishments.push(establishment);
			}
			return establishments;
		});
	}

	public getCurrent(): Promise<Establishement|null> {
		let currentEstablishmentUid = Session.getCurrentEstablishmentUid();
		if(currentEstablishmentUid) {
			let cached = this.getCachedEstablishment(currentEstablishmentUid);
			if(cached !== null) {
				return Promise.resolve(cached);
			} else {
				return this.getLight(Session.getCurrentEstablishmentUid()).then((establishment: Establishement) => {
					this.addEstablishmentToCache(establishment);

					return establishment;
				});
			}
		} else {
			return Promise.reject(Error_Api.NO_ESTABLISHMENT).catch(()=>{
				return null;
			});
		}
	};

	public get(establishmentUuid: Uuid): Promise<Establishement> {
		let cached = this.getCachedEstablishment(establishmentUuid);
		if(cached !== null) {
			return Promise.resolve(cached);
		} else {
			if (!this.promiseEstablishment) {
				this.promiseEstablishment = this.request(this.apiUrl + 'v1/Establishments/' + establishmentUuid + '/', '', 'GET', false);
			}
			return this.promiseEstablishment.then((JSONEstablishment: any) => {
				let establishment = new Establishement(JSONEstablishment);
				this.addEstablishmentToCache(establishment);

				return establishment;
			});
		}
	};

	public getLight(establishmentUuid: Uuid): Promise<Establishement> {
		return this.request(this.apiUrl + 'v1/Establishments/' + establishmentUuid + '/Light/', '', 'GET', false).then(function(JSONEstablishment: any) {
			return new Establishement(JSONEstablishment);
		});
	};

	public update(establishment: Establishement): Promise<any> {
		return this.request(this.apiUrl + 'v1/Establishments/' + establishment.uid + '/', establishment.exportToJson(), 'PUT').then(function (JSON: any) {
		});
	};

	public getUserEstablishments(userUid: Uuid): Promise<Array<ProfileEstablishment>> {
		return this.request(this.apiUrl + 'v1/Users/' + userUid + '/Partners/', '', 'GET').then(function (rawUserPartners: Array<string>) {
			if (rawUserPartners !== null) {
				let userPartners: Array<ProfileEstablishment> = [];
				for (let userEstablishment of rawUserPartners) {
					userPartners.push(new ProfileEstablishment(userEstablishment));
				}
				return userPartners;
			} else {
				return Promise.reject(new Error('getUserEstablishments() Fail')).then(function (error) {
					// not called
				}, function (error) {
					console.log(error);
				});
			}
		});

	};

	public create(newEstablishement: Establishement): Promise<any> {
		let data = {
			uid: null,
			name: newEstablishement.name,
			profileRepresentativeUid: newEstablishement.profileRepresentativeUid,
		};
		return this.request(this.apiUrl + 'v1/Establishments/', data, 'POST').then(function (JSONCreateEstablishment: any) {
			return new Establishement(JSONCreateEstablishment);
		});
	};

	public getCompanyFromKbisSociete_url(): string {
		return 'https://api.kbis-societe.fr/api/v1/France/Companies/Search/{search}'
	}

	public getCompanyWithSiren_from_KbisSociete(siren: string): Promise<LegalInformation> {
		return this.request("https://api.kbis-societe.fr/api/v1/France/Companies/" + siren + "/", null, "GET", false).then(function (JSONLegalInformation: any) {
			let legalInfo: LegalInformation = new LegalInformation();
			legalInfo.companyName = JSONLegalInformation.denomination;
			legalInfo.siren = JSONLegalInformation.siren;
			legalInfo.NIC = JSONLegalInformation.nic_siege;
			legalInfo.vatNumber = JSONLegalInformation.vat;
			legalInfo.legalFormLongVersion = JSONLegalInformation.categorie_juridique_libelle;

			//Address : adresse du siège social
			legalInfo.address.line1 = JSONLegalInformation.etablissement_siege.geo_l4;
			legalInfo.address.zipCode = JSONLegalInformation.etablissement_siege.code_postal;
			legalInfo.address.city = JSONLegalInformation.etablissement_siege.libelle_commune;
			legalInfo.address.country = "FRANCE";

			//RCS : on prend le premier pour le moment
			if (JSONLegalInformation.greffes) {
				for (let greffe of JSONLegalInformation.greffes) {
					legalInfo.rcs = greffe.name;
					break;
				}
			}

			//Contact
			if (JSONLegalInformation.phone_number)
				legalInfo.phoneNumber = JSONLegalInformation.phone_number;
			if (JSONLegalInformation.email)
				legalInfo.email = JSONLegalInformation.email;

			return legalInfo;
		});
	}
}