import Repository from "./Repository";

export default class EventProxyRepository extends Repository {
	constructor() {
		super();
	}

	public sendEvent(establishmentUid: string, event: string, email: string, data: any) {
		return this.request(this.apiUrl + 'v1/Establishments/' + establishmentUid + '/EventProxy/', {
			event: event,
			email: email,
			data: data,
		}, 'POST', false);
	}
}