import {Router} from "horizon-js-front-sdk/lib/Router";
import EstablishmentRepository from "../../repositories/EstablishmentRepository";
import {Autowire} from "horizon-js-front-sdk/lib/DependencyInjector";

export default class GtmManager {
	public static dataLayerName = 'gtmDataLayer';
	public lastEstablishmentUid: string|null = null;
	public lastContainerId: string|null = null;

	@Autowire(EstablishmentRepository) establishmentRepository!: EstablishmentRepository;

	setupGtm(containerId: string) {
		window[GtmManager.dataLayerName] = window[GtmManager.dataLayerName] || [];

		(function(w, d, s, l, i) {
			window[GtmManager.dataLayerName].push({
				'gtm.start': new Date().getTime(),
				event: 'gtm.js'
			});

			let f = d.getElementsByTagName('script')[0],
				j = d.createElement('script');
			j.async = true;
			j.src = 'https://www.googletagmanager.com/gtm.js?id=' + containerId + '&l=' + GtmManager.dataLayerName;
			j.id = 'gtm-script-tag-' + containerId;
			if(f && f.parentNode) {
				f.parentNode.insertBefore(j, f);
			}
		})(window, document, 'script', 'dataLayer', containerId);
	}

	setUpEstablishmentGtm(establishmentUid: string): Promise<any> {
		if(this.lastEstablishmentUid !== null) {
			this.clearCurrentGtm();
		}

		return this.establishmentRepository.get(establishmentUid).then((establishment) => {
			if (establishment !== null && establishment.options && establishment.options.gtm && establishment.options.gtm.containerId) {
				this.lastEstablishmentUid = establishmentUid;
				this.lastContainerId = establishment.options.gtm.containerId;
				this.setupGtm(establishment.options.gtm.containerId);
			}
		});
	}

	refreshGtm(): Promise<any> {
		let matches = Router.getMainRouter()?.lastRouteRegexMatches;
		if (matches) {
			let establishmentUid = matches[1];
			if(establishmentUid && establishmentUid !== this.lastEstablishmentUid) {
				return this.setUpEstablishmentGtm(establishmentUid);
			}
		}
		return Promise.resolve();
	}

	clearCurrentGtm() {
		// Empty previous events
		window[GtmManager.dataLayerName] = [];
		// Remove js tag
		$('#gtm-script-tag-' + this.lastContainerId).remove();
	}
}