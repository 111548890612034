import Model from "gaia-sdk-js/lib/src/class/Model";

export default class Introspection extends Model {
	public uid!: Uuid | null;
	public accessToken!: string;
	public timestamp!: number;
	public exp!: number;
	public iat!: number;

	/** Constructor **/
	constructor(obj: any = {}) {
		super();
		if (typeof obj === 'string') {
			obj = JSON.parse(obj);
		}
		Introspection.buildFromJson(this, obj);
	}

	/** Functions **/
	private static buildFromJson(instance: any, json: any) {
		if (json) {
			for (let prop in json) {
				if (json.hasOwnProperty(prop)) {
					// if (prop === 'fields') {
					//     for (let subProp in json[prop]) {
					//         if (json[prop].hasOwnProperty(subProp)) {
					//             instance.fields.push(new Field({
					//                 key: subProp,
					//                 translations: json[prop][subProp]
					//             }));
					//         }
					//     }
					// } else if (prop === 'name' || prop === 'description') {
					//     instance[prop] = new Translations(json[prop]);
					// }  else {
					instance[prop] = json[prop];
					// }
				}
			}
		}
	}

	is_expired(): boolean {
		return this.exp < new Date().getTime() / 1000;
	}

	public exportToJson(): any {
		let JSONIntrospection: any = {};
		JSONIntrospection['uid'] = this.uid;
		JSONIntrospection['accessToken'] = this.accessToken;
		JSONIntrospection['timestamp'] = this.timestamp;
		JSONIntrospection['exp'] = this.exp;
		JSONIntrospection['iat'] = this.iat;
		return JSONIntrospection;
	}

}
