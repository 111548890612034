import Repository from "./Repository";
import {Autowire} from "horizon-js-front-sdk/lib/DependencyInjector";
import {Native} from "native-universal-container-bridge/lib/native";

export class NotificationRepository extends Repository {

	@Autowire(Native.PushNotification) pushNotification!: Native.PushNotification;
	@Autowire(Native.SystemInfo) systemInfo!: Native.SystemInfo;

	constructor() {
		super();
	}

	send(profileUid: string, token: string, appBundleId: string) {
		return this.request(this.apiUrl + 'v1/Profiles/' + profileUid + '/RegisterNotificationRegistrationToken/', {
			registrationToken: token,
			appBundleId: appBundleId
		}, 'PUT', false);
	}

	askSubscription(profileUid: string) {
		return this.systemInfo.getAppInfo().then((infos) => {
			console.log(infos);
			return this.pushNotification.subscribe().then((token) => {
				return this.send(profileUid, token.token, infos.packageName);
			}).catch((err) => console.log(err));
		});
	}
}