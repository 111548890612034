import Repository from "./Repository";
import {HorizonJs_Url, UrlParameters} from "horizon-js-front-sdk/lib/Url";
import Profile from "gaia-sdk-js/lib/src/class/Identity/Profile";
import ProfileEstablishment from "gaia-sdk-js/lib/src/class/ProfileEstablishment";
import Characteristic from "gaia-sdk-js/lib/src/class/Identity/Characteristic";

export type ProfileDataCache = {
	uid: string,
	fullname: string | null,
	email: string | null,
	creationDate: string,
	updateDate: string,
}

export class ProfileRepository extends Repository {
	private profileDataCache: {[profileUid: string]: ProfileDataCache} = {};
	private profileCache: {[profileUid: string]: Profile} = {};

	constructor() {
		super();
	}

	public getAll(filters?: UrlParameters): Promise<ProfileDataCache[]> {
		return this.request(this.config.identityGateway_apiUrl + 'v1/Profiles/' + HorizonJs_Url.formatParam(filters), 'GET').then(function (profilesDataCache: ProfileDataCache[]) {
			return profilesDataCache;
		});
	}

	public getOne(uid: Uuid): Promise<ProfileDataCache> {
		if(this.profileDataCache[uid]) {
			return Promise.resolve(this.profileDataCache[uid]);
		}
		return this.request(this.config.identityGateway_apiUrl + 'v1/Profiles/' + uid + '/', 'GET').then((profileDataCache: ProfileDataCache) => {
			this.profileDataCache[uid] = profileDataCache;
			return profileDataCache;
		});
	}

	public getOneFormatted(uid: Uuid): Promise<Profile> {
		if(this.profileCache[uid]) {
			return Promise.resolve(this.profileCache[uid]);
		}
		return this.request(this.config.identityGateway_apiUrl + 'v1/Profiles/' + uid + '/', 'GET').then((JSONProfile: string) => {
			this.profileCache[uid] = new Profile(JSONProfile);
			return new Profile(JSONProfile);
		});
	}

	public getEmails(profileUid: Uuid): Promise<Array<ProfileEstablishment>> {
		return this.request(this.config.identityGateway_apiUrl + 'v1/Profiles/' + profileUid + '/Emails/', '', 'GET').then(function (emails: Array<any>) {
			return emails;
		});
	};

	public getCompanyLinkedProfiles(companyUid: Uuid): Promise<Profile[]> {
		return this.request(this.config.identityGateway_apiUrl + 'v1/Companies/' + companyUid + '/LinkedProfiles/', null, 'GET').then((JSONProfiles: any) => {
			let profiles: Profile[] = [];
			for (let JSONProfile of JSONProfiles) {
				profiles.push(new Profile(JSONProfile));
			}
			return profiles;
		});
	}

	public addCharacteristics(profileUid, characteristic: Characteristic): Promise<Characteristic> {
		return this.request(this.config.identityGateway_apiUrl + 'v1/Profiles/' + profileUid + '/Characteristic/', characteristic.exportToJson(), 'POST').then((characteristic: Characteristic) => {
			return new Characteristic(characteristic);
		});
	}
}