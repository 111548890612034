import Model from "gaia-sdk-js/lib/src/class/Model";

export default class IframeParameters extends Model {
	public primaryColor: string|null = null;
	public hiddenHeader: boolean = false;
	public hiddenFooter: boolean = false;
	public hiddenEventInfos: boolean = false;
	public iframeContainerId:string|null = null;

	constructor(obj: any = {}) {
		super();
		if (typeof obj === 'string') {
			obj = JSON.parse(obj);
		}
		IframeParameters.buildFromJson(this, obj);
	}

	private static buildFromJson(instance: any, json: any) {
		if (json) {
			for (let prop in json) {
				if (json.hasOwnProperty(prop)) {
					instance[prop] = json[prop];
				}
			}
		}
	}

	exportToJson(): any {
		let JSONCart: any = {};

		JSONCart.primaryColor = this.primaryColor;
		JSONCart.hiddenHeader = this.hiddenHeader;
		JSONCart.hiddenFooter = this.hiddenFooter;
		JSONCart.hiddenEventInfos = this.hiddenEventInfos;
		JSONCart.iframeContainerId = this.iframeContainerId;

		return JSONCart;
	}
}