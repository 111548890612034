import {VueClass, VueVar} from "horizon-js-front-sdk/lib/VueAnnotate";
import {Autowire} from "horizon-js-front-sdk/lib/DependencyInjector";
import CartRepository from "../../repositories/CartRepository";
import Vue from "vue";
import {Router} from "horizon-js-front-sdk/lib/Router";
import Cart from "../../model/Cart";
import Introspection from "../../model/Introspection";
import {ProfileRepository} from "../../repositories/ProfileRepository";
import UserRepository from "../../repositories/UserRepository";
import AuthenticationManager from "horizon-js-front-sdk/lib/AuthenticationManager";
import Config from "../../Config";
import {Company} from "gaia-sdk-js/lib/src/class/Identity/Company";
import User from "gaia-sdk-js/lib/src/class/Identity/User";
import Profile from "gaia-sdk-js/lib/src/class/Identity/Profile";

@VueClass()
export class NavbarComponent extends Vue {
	@VueVar() cartNumber: number = 0;
	@VueVar() establishmentUid!: Uuid;
	@VueVar() connectedProfile: User | Company | null = null;
	@VueVar() openMobileMenu: boolean = false;

	@VueVar() year: number = new Date().getFullYear();

	@Autowire(Config) config!: Config;
	@Autowire(UserRepository) userRepository!: UserRepository;
	@Autowire(CartRepository) cartRepository!: CartRepository;
	@Autowire(ProfileRepository) profileRepository!: ProfileRepository;

	constructor(container: string, vueConstructor: any = null) {
		super(vueConstructor);
		let introspect: Introspection = this.userRepository.introspect();


		let matches = Router.getMainRouter()?.lastRouteRegexMatches;
		if (matches) {
			this.establishmentUid = matches[1];
			this.cartNumber = this.cartRepository.getByProfileEstablishment(this.establishmentUid, introspect.uid).order.getTotalQuantity();
		}

		if (introspect.uid) {
			if (AuthenticationManager.isConnected()) {
				this.profileRepository.getOneFormatted(introspect.uid).then((profile: Profile) => {
					if (profile.isDirectUser()) {
						this.connectedProfile = profile.getUser();
					} else if (profile.isDirectCompany()) {
						this.connectedProfile = profile.getCompany();
					}
				});
			}
		}

		this.cartRepository.subscribe((cart: Cart) => {
			this.cartUpdateHandler(cart);
		});
	}

	getUserName() {
		if (this.connectedProfile !== null && this.connectedProfile.fullname!==null) {
			return this.connectedProfile.fullname;
		}
		return "Mon compte";
	}

	redirectToAuth() {
		window.location.href = this.config.pl_serviceUrl + 'auth.html?redirectAfter=' + encodeURIComponent(window.location.href);
	}

	cartUpdateHandler(cart: Cart) {
		this.cartNumber = cart.order.getTotalQuantity();
	}
}