import FileRepository from "./repositories/FileRepository";
import {Logger} from "horizon-js-front-sdk/lib/Logger";
import {DependencyInjector, DependencyInjectorInstance} from "horizon-js-front-sdk/lib/DependencyInjector";
import {Router} from "horizon-js-front-sdk/lib/Router";
import Cleave from 'vue-cleave-component';
import "jquery";
import "fomantic-classic/src/semantic.less";
import "fomantic-classic/src/semantic.js";
import "./assets/css/gaiaUser.css";
import AuthenticationManager from "horizon-js-front-sdk/lib/AuthenticationManager";
import "horizon-js-front-sdk/lib/xdomain.min.js";
import Config from "./Config";
import Vue from "vue";
import {NavbarComponent} from "./controller/Components/NavbarComponent";
import moment from "moment";
import "moment/locale/fr";
import {FooterComponent} from "./controller/Components/FooterComponent";
import * as Sentry from "@sentry/browser";
import {Integrations} from "@sentry/tracing";
import {CustomizeRouterListener} from "horizon-js-front-sdk/lib/CustomizeRouterListener";
import ColorUtils from "./class/Util/ColorUtils";
import GtmManager from "./class/Util/GtmManager";
import {Native} from "native-universal-container-bridge/lib/native";
import IframeParameters from "./model/IframeParameters";
import UserRepository from "./repositories/UserRepository";
import {NotificationRepository} from "./repositories/NotificationRepository";

//setting logger on info
Logger.level = Logger.INFO;
let injector: DependencyInjector = DependencyInjectorInstance();

let config: Config = new Config();

function initApp() {
	Vue.use(Cleave);
	moment().locale('fr');
	injector.register(Config, config);

	try {
		Sentry.init({
			dsn: config.sentryDsn,
			environment: config.sentryEnvironment,
			integrations: [new Integrations.BrowserTracing(), new Sentry.Integrations.GlobalHandlers({
				onerror: true,
				onunhandledrejection: true
			}), new Sentry.Integrations.TryCatch({eventTarget: false})],

			// Set tracesSampleRate to 1.0 to capture 100%
			// of transactions for performance monitoring.
			// We recommend adjusting this value in production
			tracesSampleRate: 1.0,
		});
		console.log("Initialized sentry");
	} catch (err) {
		console.error(err);
	}

	if (window.location.pathname === '/index.html' || window.location.pathname === '/') {
		let oldRoute = Router.extractPageFromUrl({
			type: 'HASHBANG',
			config: {registerGlobalInterceptor: false, prefix: '#!'}
		});
		if (oldRoute !== 'index')
			window.location.href = '/' + oldRoute + '.html';
	}

	let router = new Router('/pages/', {
		type: 'URL',
		config: {
			suffixes: ['.html'],
			prefix: '/',
			registerGlobalInterceptor: true
		}
	}, 'page');
	router.scrollToTop = true;

	if (config.customization) {
		new CustomizeRouterListener(router, config.customization);
	}

	// if (BrowserDetector.isBlacklistedBrowser() && window.location.pathname !== '/bad-browser') {
	// 	console.log(window.location.pathname);
	// 	window.location.href = '/bad-browser?from=' + window.location.href;
	// }

	let uuidRegex = /([0-9a-f]{8}\-[0-9a-f]{4}\-[0-9a-f]{4}\-[0-9a-f]{4}\-[0-9a-f]{12})/;

	router.addRoute({location: 'index', loader: () => import('./pages/index')});
	router.addRoute({
		regex: new RegExp(/etablissement\//.source + uuidRegex.source + /$/.source),
		loader: () => import('./pages/events')
	});
	router.addRoute({
		regex: new RegExp(/etablissement\//.source + uuidRegex.source + /\/accueil/.source),
		loader: () => import('./pages/events')
	});
	router.addRoute({location: 'auth', loader: () => import('./pages/auth')});
	router.addRoute({location: 'CookiePolicy', loader: () => import('./pages/general/CookiePolicy')});
	router.addRoute({location: 'PaymentSecurity', loader: () => import('./pages/general/PaymentSecurity')});
	router.addRoute({location: 'LegalNotice', loader: () => import('./pages/general/LegalNotice')});
	router.addRoute({location: 'inscription', loader: () => import('./pages/register')});
	router.addRoute({location: 'bad-browser', loader: () => import('./pages/badBrowser')});
	router.addRoute({
		regex: new RegExp(/etablissement\//.source + uuidRegex.source + /\/produits/.source),
		loader: () => import('./pages/products')
	});
	router.addRoute({
		regex: new RegExp(/etablissement\//.source + uuidRegex.source + /\/panier/.source),
		loader: () => import('./pages/cart')
	});
	router.addRoute({
		regex: new RegExp(/etablissement\//.source + uuidRegex.source + /\/facturation/.source),
		loader: () => import('./pages/selectInvoiceAddress')
	});
	router.addRoute({
		regex: new RegExp(/etablissement\//.source + uuidRegex.source + /\/recapitulatif\//.source + uuidRegex.source),
		loader: () => import('./pages/paymentValidation')
	});
	router.addRoute({
		regex: new RegExp(/etablissement\//.source + uuidRegex.source + /\/produit\//.source + uuidRegex.source),
		loader: () => import('./pages/product')
	});
	router.addRoute({
		regex: new RegExp(/etablissement\//.source + uuidRegex.source + /\/evenement\//.source + uuidRegex.source),
		loader: () => import('./pages/event')
	});
	router.addRoute({
		regex: new RegExp(/etablissement\//.source + uuidRegex.source + /\/ticket\//.source + uuidRegex.source + /\/placement/.source),
		loader: () => import('./pages/selectTicketFinalNode')
	});
	router.addRoute({
		regex: new RegExp(/etablissement\//.source + uuidRegex.source + /\/commandes/.source),
		loader: () => import('./pages/orders')
	});
	router.addRoute({regex: new RegExp(/commandes/.source), loader: () => import('./pages/orders')});
	router.addRoute({
		regex: new RegExp(/etablissement\//.source + uuidRegex.source + /\/compte/.source),
		loader: () => import('./pages/account')
	});
	router.addRoute({regex: new RegExp(/compte/.source), loader: () => import('./pages/account')});
	router.addRoute({
		regex: new RegExp(/etablissement\//.source + uuidRegex.source + /\/evenements/.source),
		loader: () => import('./pages/events')
	});

	router.addRoute({
		regex: new RegExp(/profil\//.source + uuidRegex.source + /\/ticket\//.source + uuidRegex.source + /\/appleWallet/.source),
		loader: () => import('./pages/appleWalletClaim')
	});

	router.addRoute({regex: new RegExp(/.*/.source), loader: () => import('./pages/404')});

	DependencyInjectorInstance().register(Router, router);

	router.updateCurrentPageFromCurrentLocation();

	//TODO : supprimer le any
	let gtmManager: GtmManager | any = injector.getInstance(GtmManager);
	window[GtmManager.dataLayerName] = window[GtmManager.dataLayerName] || [];

	if(config.gtmId) {
		gtmManager.setupGtm(config.gtmId);
	}

	router.addEventListener('new_route',() => {
		gtmManager.refreshGtm().then(() => {
			window[GtmManager.dataLayerName].push({
				event: 'Pageview',
				pagePath: window.location.pathname,
			});
		});
	});

	let navbar: NavbarComponent = new NavbarComponent('#navbar');
	injector.register(NavbarComponent, navbar);

	let footer: FooterComponent = new FooterComponent('#footer');
	injector.register(FooterComponent, footer);

	//TODO : supprimer le any
	let loadingScreenNative: Native.LoadingScreen | any = DependencyInjectorInstance().getInstance(Native.LoadingScreen);
	loadingScreenNative.disable().catch(() => {});

	if (AuthenticationManager.isConnected()) {
		let fileRepository: FileRepository = new FileRepository();
		injector.register(FileRepository, fileRepository);

		let pushNotifications = DependencyInjectorInstance().getInstance(Native.PushNotification);
		let userRepository = DependencyInjectorInstance().getInstance(UserRepository);
		let notificationRepository = DependencyInjectorInstance().getInstance(NotificationRepository);
		pushNotifications.info().then((infos) => {
			if(infos.needAuthorization && infos.authorizationGivenByUser) {
				let instrospect = userRepository.introspect();
				if(instrospect.uid) {
					notificationRepository.askSubscription(instrospect.uid);
				}
			}
		});
	}

	if (config.pl_name) {
		document.title = config.pl_name;
	}
	//User
	//Establishments
	setIFrameParameters();
}

function setIFrameParameters() {
	let lsCode = 'iframe-parameters';
	if (window !== window.parent) {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);

		let urlPrimaryColor: string|null = urlParams.get('primaryColor');
		let urlHiddenHeader: string|null = urlParams.get('hiddenHeader');
		let urlHiddenFooter: string|null = urlParams.get('hiddenFooter');
		let urlHiddenEventInfos: string|null = urlParams.get('hiddenEventInfos');
		let urlIFrameContainerId: string|null = urlParams.get('iframeContainerId');

		let rawIframeParameters = sessionStorage.getItem(lsCode);
		let iframeParameters = new IframeParameters();
		if(rawIframeParameters) {
			iframeParameters = new IframeParameters(JSON.parse(rawIframeParameters));
		}

		iframeParameters.primaryColor = urlPrimaryColor ?? iframeParameters.primaryColor;
		iframeParameters.hiddenHeader = urlHiddenHeader ? urlHiddenHeader === 'true' : iframeParameters.hiddenHeader;
		iframeParameters.hiddenFooter = urlHiddenFooter ? urlHiddenFooter === 'true' : iframeParameters.hiddenFooter;
		iframeParameters.hiddenEventInfos = urlHiddenEventInfos ? urlHiddenEventInfos === 'true' : iframeParameters.hiddenEventInfos;
		iframeParameters.iframeContainerId = urlIFrameContainerId ?? iframeParameters.iframeContainerId;

		if (iframeParameters.iframeContainerId) {
			let lastHeight = document.documentElement.scrollHeight;
			let sendHeightToParent = function() {
				window.parent.postMessage({event: 'content_height', height: document.documentElement.scrollHeight, containerId: iframeParameters.iframeContainerId}, '*');
			}
			setInterval(() => {
				if (lastHeight !== document.documentElement.scrollHeight) {
					lastHeight = document.documentElement.scrollHeight;
					sendHeightToParent();
				}
			}, 100);
			sendHeightToParent();
		}

		const styleEl = document.createElement('style');
		document.head.appendChild(styleEl);
		let styleSheet = styleEl.sheet;
		if(styleSheet) {
			if(iframeParameters.primaryColor) {
				const isLight = ColorUtils.hexIsLight(iframeParameters.primaryColor);

				styleSheet.insertRule('.primary { background-color: ' + iframeParameters.primaryColor + ' !important; }');
				styleSheet.insertRule('.primary { color: ' + (isLight ? 'black' : 'white') + ' !important; }');
			}
			if(iframeParameters.hiddenHeader) {
				styleSheet.insertRule('#navbar { display: none !important; }');
			}
			if(iframeParameters.hiddenFooter) {
				styleSheet.insertRule('#footer { display: none !important; }');
			}
			if(iframeParameters.hiddenEventInfos) {
				styleSheet.insertRule('[data-iframe="hidden"] { display: none !important; }');
				styleSheet.insertRule('[data-iframe="no-shadow"] { box-shadow: none !important; }');
			}
		}
		sessionStorage.setItem(lsCode, JSON.stringify(iframeParameters.exportToJson()));
	}
}

if (!config.isValid()) {
	config.getFromServer().then(function (JSONConfig: any) {
		config = new Config(JSONConfig);
		let xDomainParameter: any = {};

		let plDomain = new URL('/', config.pl_apiUrl).href;
		if (plDomain) {
			let nddWithoutSlashAtTheEnd: string = '';
			if (plDomain.charAt(plDomain.length - 1) === '/') {
				nddWithoutSlashAtTheEnd = plDomain.substring(0, plDomain.length - 1);
			}
			xDomainParameter[nddWithoutSlashAtTheEnd] = "/api/cors";
		}
		xdomain.slaves(xDomainParameter);

		let storedVersion = window.localStorage.getItem('version');
		if (storedVersion !== null) {
			window.location.reload();
		}
		initApp();
	}).catch(function (error) {
		console.log("Error : Index.ts " + error);
	});
} else {
	initApp();
}
